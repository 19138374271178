


































.support {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    padding: 150px 0 20px;
    background: #f2f4f6;
    position: relative;
    @media (max-width: 1440px) {
      padding: 120px 0 20px;
    }
  }
  .section-2 {
    padding: 20px 0 10px;
    position: relative;
    padding: 1rem;
    margin: 1rem -15px 0;
    border: solid #f8f9fa;
    border-width: 0.2rem 0 0;
    .container {
      max-width: 768px;
    }
  }
}
